import type { HeaderFragment } from '~/model/api.ts'
import { Cols } from '~/components/cols.tsx'
import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { Input } from '~/components/input.tsx'
import { Button, buttonVariants } from '@rouvydev/web-components/basics'
import { ErrorList } from '~/components/errors-list.tsx'
import { useFetcher } from '@remix-run/react'
import type { action } from '~/routes/resources.newsletter.ts'
import { z } from 'zod'
import { EmailSchema } from '~/utils/schema.ts'
import { Gradient5 } from '~/components/gradients.tsx'
import { Image } from '~/components/image.tsx'
import { trackEvent } from '~/utils/gtm.client.ts'
import { cn } from '@rouvydev/web-components/utils'
import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '~/components/normalized-link.tsx'

export function prepareHeaderProps(data: HeaderFragment) {
  return {
    id: data.id,
    backgroundImage: data.backgroundImage,
    responsiveImage: data.responsiveImage,
    headerImage: data.headerImage,
    headerButton: data.headerButton,
    headerDescription: data.headerDescription,
    headline: data.headline,
    campaignId: data.headerCampaignId,
    button: data.headerButton,
    buttonAlt: data.buttonAlt,
    subheading: data.subheading,
    linkAfterEmailSubmit: data.linkAfterEmailSubmit,
  }
}

type Props = ReturnType<typeof prepareHeaderProps>

export const RegisterSchema = z.object({
  email: EmailSchema,
  linkAfterEmailSubmit: z.string().nullish(),
})

export function Header(props: Props) {
  const { t } = useTranslation()
  const {
    headline,
    backgroundImage,
    responsiveImage,
    campaignId,
    buttonAlt,
    button,
    headerDescription,
    subheading,
    linkAfterEmailSubmit,
  } = props
  const fetcher = useFetcher<typeof action>()

  const [form, fields] = useForm({
    id: 'header-form',
    constraint: getFieldsetConstraint(RegisterSchema),
    defaultValue: { email: '' },
    lastSubmission: fetcher.data?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: RegisterSchema })
    },
    onSubmit(_, { formData }) {
      trackEvent('email_captured', {
        campaignId: campaignId,
        formType: 'lead',
        capturedEmail: formData.get('email'),
      })
    },
    shouldRevalidate: 'onBlur',
  })

  const busy = fetcher.state !== 'idle'

  return (
    <div>
      <div className="relative overflow-hidden bg-background-canvas">
        <Gradient5 />
        {backgroundImage?.data?.attributes && (
          <div className="absolute inset-0">
            <Image
              className={cn(
                responsiveImage?.data?.attributes ? 'hidden' : 'block',
                'h-full w-full object-cover md:block',
              )}
              src={backgroundImage?.data?.attributes?.url}
              formats={backgroundImage.data?.attributes?.formats}
              width={backgroundImage.data?.attributes?.width ?? 0}
              height={backgroundImage.data?.attributes?.height ?? 0}
              loading="eager"
              alt={backgroundImage.data?.attributes.alternativeText ?? ''}
            />
          </div>
        )}
        <div className="container relative">
          <div
            className={cn(
              !responsiveImage?.data?.attributes ? 'h-[632px] ' : '',
              'relative z-[100] flex flex-col gap-4 pt-[88px] md:h-[608px] lg:h-[800px] lg:pt-[120px]',
            )}
          >
            {responsiveImage?.data?.attributes && (
              <div className="-mx-4 -mt-[88px] md:hidden">
                <Image
                  className="block h-full w-full object-cover"
                  src={responsiveImage?.data?.attributes?.url}
                  formats={responsiveImage.data?.attributes?.formats}
                  width={responsiveImage.data?.attributes?.width ?? 0}
                  height={responsiveImage.data?.attributes?.height ?? 0}
                  loading="eager"
                  alt={responsiveImage.data?.attributes.alternativeText ?? ''}
                />
              </div>
            )}
            <div className="flex flex-1 items-end pb-20 md:items-center md:pb-0">
              <Cols size="6" className="mx-0 w-full">
                <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
                  {subheading && (
                    <p className="text-center text-[14px] font-semibold text-global-white md:text-left md:text-[20px]">
                      {subheading}
                    </p>
                  )}
                  <h1 className="text-center font-display text-display-40 font-extrabold uppercase italic leading-[40px] text-text-default md:text-left md:text-display-60 md:leading-[60px] lg:text-[4.5rem] lg:leading-[72px]">
                    {headline}
                  </h1>
                  {headerDescription && (
                    <p className="text-center text-body-16 font-semibold text-text-muted md:text-left md:text-body-18 lg:text-body-20">
                      {headerDescription}
                    </p>
                  )}
                  {campaignId && (
                    <fetcher.Form
                      action="/resources/register"
                      {...form.props}
                      method="POST"
                      className="w-full"
                    >
                      <div className="flex flex-col gap-4 md:flex-row">
                        <div className="flex flex-1 flex-col gap-1">
                          <Input
                            {...conform.input(fields.email)}
                            className="flex-1"
                            placeholder={t('email_input_placeholder')}
                          />
                        </div>
                        <Button
                          type="submit"
                          className="min-w-[140px] justify-center"
                          variant="primary"
                          disabled={busy}
                        >
                          {busy ? t('loading') : t('get_started_button')}
                        </Button>
                      </div>

                      <ErrorList
                        errors={
                          fields.email.errors?.length
                            ? fields.email.errors
                            : ['\u00A0']
                        }
                      />
                      <input
                        type="hidden"
                        name="campaignId"
                        value={campaignId}
                      />

                      <input
                        type="hidden"
                        name="linkAfterEmailSubmit"
                        value={linkAfterEmailSubmit ?? undefined}
                      />
                    </fetcher.Form>
                  )}
                  {!campaignId && button && (
                    <div className="flex gap-4 max-md:flex-col md:flex-wrap">
                      <NormalizedLink
                        to={button.link}
                        className={cn(
                          'justify-center',
                          buttonVariants({
                            variant: button.buttonStyle ?? 'primary',
                          }),
                        )}
                      >
                        {button.text}
                      </NormalizedLink>
                      {buttonAlt && (
                        <NormalizedLink
                          to={buttonAlt.link}
                          className={cn(
                            ' justify-center',
                            buttonVariants({
                              variant: buttonAlt.buttonStyle ?? 'primary',
                            }),
                          )}
                        >
                          {buttonAlt.text}
                        </NormalizedLink>
                      )}
                    </div>
                  )}
                </div>
              </Cols>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
